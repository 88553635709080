import type {IPaymentType} from "../types/application.types";
import {Chip, ChipProps} from "@mui/material";
import {paymentTypes} from "../services/utils";

interface IProps extends ChipProps {
    type: IPaymentType
}

function PaymentTypeChip({type, className, ...props}: IProps) {
    return (
        <Chip label={paymentTypes[type]} sx={{backgroundColor: "#ddd", border: "1px solid #ccc"}} {...props}/>
    )
}

export default PaymentTypeChip
