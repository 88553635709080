import {useState} from "react"
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import Button from "@mui/material/Button";

interface IProps {
    text: string
    maxLength?: number
    setCustomOpen?: (v: boolean) => void
    customOpen?: boolean
}

function ExpandableText({
                            text,
                            maxLength = 55,
                            setCustomOpen,
                            customOpen,
                        }: IProps) {
    // States
    const [isOpened, setOpen] = useState(false)
    const open = customOpen || isOpened

    // Functions
    const isExceeded = text.length > maxLength
    const changedText = !open && isExceeded ? text.substring(0, maxLength) : text

    return (
        <div>
            {changedText} {!open && isExceeded ? "... " : null}
            {isExceeded ? (
                <Button
                    size="small"
                    sx={{
                        textTransform: "capitalize",
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (setCustomOpen) {
                            setCustomOpen(!open)
                        } else {
                            setOpen((prev) => !prev)
                        }
                    }}
                >
                    <p>{!open && isExceeded ? "Batafsil" : "Yashirish"}</p>
                    {open ? <ArrowUpward sx={{width: "18px", ml: 1}}/> : <ArrowDownward sx={{width: "18px", ml: 1}}/>}
                </Button>
            ) : null}
        </div>
    )
}

export default ExpandableText
