import React from 'react';
import {Link, useLocation} from "react-router-dom";
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import QrCode from '@mui/icons-material/QrCode';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import InventoryIcon from '@mui/icons-material/Inventory';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import {useTheme} from "@mui/material/styles";
import {Assignment, AssignmentInd} from "@mui/icons-material";

const menuItems = [
    {path: "/", icon: <DashboardIcon/>, text: "Dashboard"},
    {path: "/categories", icon: <LayersIcon/>, text: "Kategoriyalar"},
    {path: "/users", icon: <PeopleIcon/>, text: "Foydalanuvchilar"},
    {path: "/qrcode", icon: <QrCode/>, text: "Qr kodlar"},
    {path: "/packets", icon: <LocalMallIcon/>, text: "Monitoring"},
    {path: "/boxes", icon: <InventoryIcon/>, text: "Yashiklar"},
    {header: "Hodimlar uchun"},
    {path: "/payme/emp", icon: <BarChartIcon/>, text: "Pul so'rovlari"},
    {path: "/earnings/emp", icon: <LocalAtmIcon/>, text: "Daromadlar"},
    {header: "Aholi uchun"},
    {path: "/payme/pop", icon: <BarChartIcon/>, text: "Pul so'rovlari"},
    {path: "/earnings/pop", icon: <LocalAtmIcon/>, text: "Daromadlar"},
    {header: "Agent"},
    {path: "/agent/list", icon: <AssignmentInd/>, text: "Agentlar"},
    {path: "/agent/applications", icon: <Assignment/>, text: "Arizalar"},
    {path: "/payme/agent", icon: <BarChartIcon/>, text: "Pul so'rovlari"},
    {path: "/earnings/agent", icon: <LocalAtmIcon/>, text: "Daromadlar"},
];

export const MainListItems = () => {
    const {pathname} = useLocation();
    const theme = useTheme();

    return (
        <>
            {menuItems.map((item, index) =>
                item.header ? (
                    <React.Fragment key={index}>
                        <hr/>
                        <ListSubheader className="sidebar-menu-item-head" component="div" inset>
                            <h3>{item.header}</h3>
                        </ListSubheader>
                    </React.Fragment>
                ) : (
                    <ListItemButton
                        key={index}
                        component={Link}
                        to={item.path!}
                        className={`sidebar-menu-item ${pathname === item.path ? 'active' : ''}`}
                        sx={{
                            ...(pathname === item.path && {
                                backgroundColor: theme.palette.primary.main,
                                color: "#eee",
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: "#eee",
                                },
                                "& svg": {
                                    fill: "#eee",
                                }
                            }),
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItemButton>
                )
            )}
        </>
    );
};
