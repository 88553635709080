import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import {categoriesServices} from "../../services/categories.services";
import {CategoryObj} from "../../types/categories.types";
import Toast from "../../components/Toast";
import {User} from "../../types/users.types";
import {Checkbox, FormControlLabel} from "@mui/material";
import {FormEvent, useEffect, useMemo, useState} from "react";

interface Props {
    onSubmit: (user: User) => void;
    user: User;
}

export default function AgentForm({onSubmit, user}: Props) {
    // States
    const [errorMessage, setErrorMessage] = useState("");
    const [newPassword, setNewPassword] = useState(false);
    const [categories_, setCategories_] = useState(user.categories);
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
    const [categories, setCategories] = useState([] as CategoryObj[]);
    const [firstName, setFirstName] = useState(user.first_name);
    const [carNumber, setCarNumber] = useState(user.car_number);
    const [lastName, setLastName] = useState(user.last_name);
    const [password, setPassword] = useState("");
    const [role, setRole] = useState(user.role);
    const [open, setOpen] = useState(false);

    // Effects
    useEffect(() => {
        categoriesServices.categories().then((res) => {
            setCategories(res as CategoryObj[]);
        });
    }, [categories, user]);

    // Functions
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (firstName === "") {
            setErrorMessage("Ismni kiriting");
            setOpen(true);
            return;
        }

        if (lastName === "") {
            setErrorMessage("Familiyani kiriting");
            setOpen(true);
            return;
        }

        if (phoneNumber === "") {
            setErrorMessage("Telefon raqamini kiriting");
            setOpen(true);
            return;
        }

        if (phoneNumber.length !== 13 || !phoneNumber.startsWith("+998")) {
            setErrorMessage("Telefon raqamini to`g`ri kiriting: +998*********");
            setOpen(true);
            return;
        }

        if (password === "" && newPassword) {
            setErrorMessage("Parolni kiriting");
            setOpen(true);
            return;
        }

        const userData: User = {
            id: user.id,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            role: "AGENT",
            categories: categories_,
            car_number: carNumber ? carNumber.replace(/ /g, "") : "",
        };
        if (password !== "" || newPassword) {
            userData.password = password;
        }
        onSubmit(userData);
    };

    const categoryOptions = useMemo(
        () =>
            categories.map((category) => {
                return {value: category.id, label: category.name};
            }),
        [categories]
    );


    const animatedComponents = makeAnimated();

    const filteredCategioories = useMemo(
        () =>
            categories_.map((item) =>
                categoryOptions.find((option) => option.value === item)
            ),
        [categories_, categoryOptions]
    );

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{mt: 3}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Ism"
                                autoFocus
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Familiya"
                                name="lastName"
                                autoComplete="family-name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Telefon raqam"
                                name="phoneNumber"
                                autoComplete="phoneNumber"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                    />
                                }
                                label="Parolni o`zgartirish"
                                onChange={() => setNewPassword(!newPassword)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {newPassword && (
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Parol"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Saqlash
                    </Button>
                </Box>
            </Box>
            <Toast
                severity="warning"
                message={errorMessage}
                isOpen={open}
                handleClose={() => setOpen(false)}
            />
        </Container>
    );
}
