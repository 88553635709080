import type {IApplicationStatus, IPaymentType} from "../types/application.types";

export const formatDateTime = (date: string | undefined | null) => {
    if (!date) return "";

    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const _date = `0${d.getDate()}`.slice(-2);
    const hour = `0${d.getHours()}`.slice(-2);
    const minute = `0${d.getMinutes()}`.slice(-2);
    return (
        <span style={{
            color: "blue",
            fontWeight: "bold",
        }}>
            {year}-{month}-{_date} {hour}:{minute}
        </span>
    );
}


export function numberWithCommas(x: number | null | undefined): string {
    if (x === null || x === undefined) return "0";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatMoney(money: number) {
    return new Intl.NumberFormat().format(money)
}

export function formatCardNumberwith4(x: number | null | undefined): string {
    if (x === null || x === undefined) return " - ";
    return x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ");
}

export function formatMoneyUZS(amount: number) {
    return new Intl.NumberFormat("uz", {
        style: "currency",
        currency: "UZS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(amount)
}

export const paymentTypes: Record<string, string> = {
    bank_account: "Balans bilan to'lash",
    invoice: "Bank o'tkazmasi",
    card: "Karta bilan",
}

export const applicationStatuses: Record<IApplicationStatus, string> = {
    in_way: "In Way",
    approved: "Tasdiqlangan",
    pending: "Kutilmoqda",
    rejected: "Bekor qilingan",
    delivered: "Delivered",
}

export function formatPhoneNumber(phoneNumber: string): string {
    // Check if the phone number starts with +998 and has the correct length
    const regex = /^\+998(\d{2})(\d{3})(\d{2})(\d{2})$/
    const match = phoneNumber.match(regex)

    if (match) {
        // Format the phone number as +998 (XX) XXX-XX-XX
        return `+998 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
    }

    // If the phone number is not valid, return the original input
    return phoneNumber
}