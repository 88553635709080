import './ecoPacketBoxCreate.scss'
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Backdrop, Button, CircularProgress, Container} from '@mui/material'
import Select from 'react-select';
import {categoriesServices} from '../../services/categories.services';
import type {CategoryObj} from '../../types/categories.types';
import {ecoPacketBoxesServices} from '../../services/ecoPacket/ecoPacketBoxes.services';
import BackLink from '../../components/BackLink';
import {requests} from "../../services/requests";
import type {IAgent} from "../../types/users.types";
import Toast, {IToastBaseData} from "../../components/Toast";

interface IOption {
    value: number,
    label: string
}

export const EcoPacketBoxCreate = () => {
    // Helpers
    const navigate = useNavigate()
    const {id: boxId} = useParams<{ id: string }>()

    // States
    const [toast, setToast] = useState<IToastBaseData>()
    const [categoryOptions, setCategoryOptions] = useState<IOption[]>([])
    const [agentOptions, setAgentOptions] = useState<IOption[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({
        name: '',
        category: 0,
        simModule: '',
        agent: 0
    })
    console.log({form})
    useEffect(() => {
        setIsLoading(true)
        if (boxId) {
            ecoPacketBoxesServices.getOneDetailed(boxId).then(res => {
                const box = res.data
                setForm({
                    category: box.category.id,
                    agent: box.category.id,
                    name: box.name,
                    simModule: box.sim_module
                })
                console.log(box)
            })
        }
        if (categoryOptions.length === 0) {
            categoriesServices.categories().then(categories => {
                setCategoryOptions(categories.map((item: CategoryObj) => {
                    return {value: item.id, label: item.name}
                }))
                setIsLoading(false)
            })
        }
        if (agentOptions.length === 0) fetchAgents()
    }, [])

    async function fetchAgents() {
        setIsLoading(true);
        const agents = await requests('GET', `account/admin-register?role=AGENT`)
        if (agents.data && agents.data.results.length) {
            setAgentOptions((agents.data.results as IAgent[]).map((agent) => ({
                value: agent.id,
                label: agent.first_name
            })))
        }
        setIsLoading(false);
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        let error = ""

        if (!form.name) error = "Nomini kiriting"
        if (!form.category) error = "Kategoriyani tanlang"
        if (!form.simModule) error = "Miqdorini kiriting"
        if (!form.agent) error = "Agent tanlang"

        if (error) {
            setToast({
                message: error,
                severity: "error",
                isOpen: true
            })
            return
        }

        setIsLoading(true)
        if (boxId) {
            ecoPacketBoxesServices.update(boxId, {...form, sim_module: form.simModule}).then(res => {
                alert("Muvaffaqiyatli o'zgartirildi!");
                navigate(`/boxes/${boxId}`)
                setIsLoading(false)
            }).catch(() => {
                alert('Kutilmagan xatolik yuz berdi');
                setIsLoading(false);
            })
        } else {
            ecoPacketBoxesServices.create({...form, sim_module: form.simModule}).then(res => {
                alert('Muvaffaqiyatli yaratildi');
                navigate(`/boxes/${res.data.id}`)
                setIsLoading(false)
            }).catch(() => {
                alert('Kutilmagan xatolik yuz berdi');
                setIsLoading(false);
            })
        }
    }
    console.log(agentOptions)
    console.log(form.agent)
    return (
        <div className="createEcoBox">
            <BackLink pathName='/boxes'/>
            <Container maxWidth="sm">
                <h1 className='title'>Yangi yashik ma'lumotlarini kiriting</h1>

                <div className="formElement">
                    <Select
                        value={categoryOptions.find(option => option.value === form.category) || null}
                        className="basic-single"
                        classNamePrefix="select"
                        isLoading={isLoading}
                        name="category"
                        onChange={(e: any) => setForm({...form, category: e.value})}
                        options={categoryOptions}
                        placeholder="Kategoriyasi"
                    />
                </div>

                <div className="formElement">
                    <input
                        value={form.name}
                        onChange={(e: any) => setForm({...form, name: e.target.value})}
                        className='numberInput'
                        name="name"
                        type="text"
                        placeholder="Nomi"
                    />
                </div>

                <div className="formElement">
                    <input
                        value={form.simModule}
                        onChange={(e: any) => setForm({...form, simModule: e.target.value})}
                        className='numberInput'
                        name="simModule"
                        type="text"
                        placeholder="Sim moduli"
                        maxLength={15}
                    />
                </div>

                <div className="formElement">
                    <Select
                        value={agentOptions.find(option => option.value === form.agent) || null}
                        className="basic-single"
                        classNamePrefix="select"
                        isLoading={isLoading}
                        name="agent"
                        onChange={(e: any) => setForm({...form, agent: e.value})}
                        options={agentOptions}
                        placeholder="Agent"
                    />
                </div>

                <div className="formElement submitBtn">
                    <Button onClick={onSubmit} fullWidth
                            variant="contained">{!boxId ? "Saqlash" : "O'zgartirish"}</Button>
                </div>

            </Container>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {
                toast ?
                    <Toast
                        {...toast}
                        handleClose={() => setToast(undefined)}
                    /> : null}
        </div>
    )
}
