import {requests} from "./requests";
import type {IApplicationStatus, IPaymentType} from "../types/application.types";

export type IAgentApplication = {
    id: number;
    box: string | null;
    amount: number;
    rejected_reason: string;
    comment: string;
    payment_type: IPaymentType;
    containers_count: number;
    created_at: string;
    updated_at: string;
    status: IApplicationStatus;
    agent: number;
    employee: string | null;
    rejected_by: string | null;
};


export const applicationsService = {
    getAll: (
        page: number,
        page_size: number,
    ) => requests('GET', `bank/agent/admin/application/list?page=${page}&page_size=${page_size}`),

    // createPayOut: (userId: string, amount: number, card: string, cardName: string) => requests('POST', 'bank/payout-list-create/', {
    //     user: userId,
    //     amount: amount,
    //     card: card,
    //     card_name: cardName
    // }),
}
