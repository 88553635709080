import {requests} from "../requests";


export const ecoPacketBoxesServices = {
    getAll: () => {
        return requests('GET', "ecopacket/box/")
    },
    getOne: (id: string) => {
        return requests('GET', `ecopacket/box/${id}/`)
    },
    getOneDetailed: (id: string) => {
        return requests('GET', `ecopacket/boxes/${id}/`)
    },
    create: (data: any) => {
        return requests('POST', `ecopacket/box/`, data)
    },
    update: (boxId: string, data: any) => {
        return requests('PUT', `ecopacket/box/${boxId}/`, data)
    },
    delete: (id: string) => {
        return requests('DELETE', `ecopacket/box/${id}/`)
    },
    getLifeCycles: (boxId: string) => {
        return requests('GET', `ecopacket/life-cycle-list/?box=${boxId}`)
    }
};