import "./users.scss";
import {ChangeEvent, useEffect, useState} from "react";
import {IconButton, TextField} from "@mui/material";
import type {User, UserFetchResponse} from "../../types/users.types";
import type {CategoryObj} from "../../types/categories.types";
import type {Column} from "../../types/table.types";
import {categoriesServices} from "../../services/categories.services";
import {getUsers} from "../../services/users.services";
import DataTable from "../../components/dataTable/DataTable";
import UserDelete from "./UserDelete";
import {Link} from "react-router-dom";
import PaidIcon from '@mui/icons-material/Paid';
import {formatPhoneNumber} from "../../services/utils";
import AgentCreate from "./AgentCreate";
import AgentUpdate from "./AgentUpdate";

const AgentsPage = () => {
    // States
    const [categories, setCategories] = useState<CategoryObj[]>([]);
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState<UserFetchResponse>({
        count: 0,
        next: null,
        previous: null,
        results: [],
    } as UserFetchResponse);

    useEffect(() => {
        setLoading(true);
        categoriesServices.categories().then((res) => {
            setCategories(res);
        });
        getUsers(search, "AGENT", page + 1, rowsPerPage).then((res) => {
            setRows(res.data);
            setLoading(false);
        });
        setLoad(false);
    }, [search, selectedRole, page, rowsPerPage, load]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns: readonly Column[] = [
        {
            id: "first_name",
            label: "Ism",
            minWidth: 90,
        },
        {
            id: "last_name",
            label: "Familiya",
            minWidth: 90,
        },
        {
            id: "phone_number",
            label: "Telefon raqami",
            minWidth: 50,
            align: "right",
            format: (row) => formatPhoneNumber(row.phone_number)
        },
        {
            id: "categories",
            label: "Konteynerlari",
            minWidth: 100,
            align: "right",
            format: () => ""
            // format: (row: User) => {
            //     return (
            //         <Stack direction="column" justifyContent="flex-end" flexWrap="wrap" gap="3px">
            //             {row.categories.length > 0
            //                 ? row.categories.map((category) => {
            //                     return (
            //                         <div key={category}>
            //                             {
            //                                 <Chip
            //                                     label={
            //                                         categories.find(
            //                                             (cat) =>
            //                                                 cat.id ===
            //                                                 category
            //                                         )?.name
            //                                     }
            //                                     size="small"
            //                                 />
            //                             }
            //                         </div>
            //                     );
            //                 })
            //                 : "Kiritilmagan"}
            //         </Stack>
            //     );
            // },
        },
        {
            id: "bank",
            label: "",
            align: "right",
            format: (row: User) => {
                return <Link to={`/user-bank/${row.id}`}>
                    <IconButton>
                        <PaidIcon/>
                    </IconButton>
                </Link>;
            },
        },
        {
            id: "edit",
            label: "",
            align: "center",
            format: (row: User) => {
                return <AgentUpdate user={row} setLoad={setLoad}/>;
            },
        },
        {
            id: "delete",
            label: "",
            align: "left",
            format: (row: User) => {
                return <UserDelete setLoad={setLoad} row={row}/>;
            },
        },
    ];

    return (
        <div className="users">
            <div className="table">
                <div className="tableHeader">
                    <div className="tableFiler">
                        <TextField
                            label="Qidirish"
                            type="search"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "5px",
                            }}
                            size={"small"}
                            onChange={handleSearch}
                        />
                        {/*<Select*/}
                        {/*    options={[*/}
                        {/*        {value: "", label: "Barchasi"},*/}
                        {/*        {value: "POP", label: "Aholi"},*/}
                        {/*        {value: "EMP", label: "Ishchi"},*/}
                        {/*        {value: "ADMIN", label: "Admin"},*/}
                        {/*    ]}*/}
                        {/*    defaultValue={{value: "", label: "Barchasi"}}*/}
                        {/*    className="basic-multi-select"*/}
                        {/*    classNamePrefix="select"*/}
                        {/*    name="role"*/}
                        {/*    isSearchable={false}*/}
                        {/*    onChange={(e: any) => {*/}
                        {/*        handleRoleSelect(e);*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>
                    <AgentCreate setLoad={setLoad}/>
                </div>
                <DataTable
                    isLoading={loading}
                    total={rows.count}
                    rows={rows.results}
                    columns={columns}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default AgentsPage;
