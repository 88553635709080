import type {IApplicationStatus} from "../types/application.types";
import type {ChipProps} from "@mui/material";
import {Chip} from "@mui/material";
import {applicationStatuses} from "../services/utils";

interface IProps extends ChipProps {
    status: IApplicationStatus
}

function ApplicationStatusChip({status, className, ...props}: IProps) {
    return (
        <Chip label={applicationStatuses[status]}
              sx={{
                  backgroundColor: getColor(status),
                  border: `1px solid ${getColor(status)}`,
                  color: "white",
              }}
              {...props}/>
    )
}

export default ApplicationStatusChip

function getColor(status: IApplicationStatus) {
    const all = {
        "approved": "#207edc",
        "delivered": "#2ecc35",
        "rejected": "#df6464",
        "in_way": "#ccc",
        "pending": "rgb(181,210,239)"
    } as Record<IApplicationStatus, string>
    return all[status]
}