import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

function NotFoundPage() {
    return (
        <Card sx={{
            height: "100vh",
            display: "grid",
            placeItems: "center",
            backgroundColor: "#eee",
            color: "#1976D2"
        }}>
            <Box sx={{textAlign: "center"}}>
                <Typography sx={{fontSize: "2rem", mt: "-2em", mb: "10px"}}>
                    Sahifa topilmadi - 404
                </Typography>
                <Link to="/">
                    <Button variant="contained">Bosh sahifaga</Button>
                </Link>
            </Box>
        </Card>
    );
}

export default NotFoundPage;