import React, {useEffect, useState} from "react";

import {Button, Stack} from "@mui/material";

import {Column} from "../../types/table.types";
import DataTable from "../../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import {paymeServices} from "../../services/payme.services";
import {formatMoneyUZS} from "../../services/utils";
import {Cancel, CheckCircle} from "@mui/icons-material";
import type {IPageable} from "../../types/util.types";
import type {IApplicationHistory} from "../../types/application.types";
import PaymentTypeChip from "../../components/PaymentTypeChip";
import ExpandableText from "../../components/ExpandableText";
import {applicationsService} from "../../services/applications.service";
import type {IAgentApplication} from "../../services/applications.service";
import {agentApiServices} from "../../services/agent.services";
import Toast, {IToastBaseData} from "../../components/Toast";
import ApplicationStatusChip from "../../components/ApplicationStatusChip";

const AgentApplications = ({userId, reload, setReloadPage}: any) => {
    const {earnerType} = useParams<string>()

    const [toast, setToast] = useState<IToastBaseData>()
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState<IPageable<IApplicationHistory[]>>({
        count: 0,
        next: null,
        previous: null,
        results: [],
    });

    useEffect(() => {
        fetchApplications()
    }, [search, page, rowsPerPage, load, earnerType, userId]);

    function fetchApplications() {
        setLoading(true);
        applicationsService.getAll(
            page + 1,
            rowsPerPage,
        ).then((res) => {
            setRows(res.data);
            setLoading(false);
        });
        setLoad(false);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns: readonly Column[] = [
        {
            id: "box",
            label: "Box",
            align: "center",
            minWidth: 50,
            format: (row: IAgentApplication) => row.box,
        },
        {
            id: "container_count",
            label: "Konteyner soni",
            align: "center",
            minWidth: 50,
            format: (row: IAgentApplication) => row.containers_count,
        },
        {
            id: "total_amount",
            label: "Jami summa",
            align: "center",
            minWidth: 50,
            format: (row: IAgentApplication) => {
                return formatMoneyUZS(row.amount);
            },
        },
        {
            id: "payment_type",
            label: "To'lov turi",
            align: "center",
            minWidth: 50,
            format: (row: IAgentApplication) => <PaymentTypeChip type={row.payment_type}/>,
        },
        {
            id: "status",
            label: "Holati",
            align: "center",
            minWidth: 50,
            format: (row: IAgentApplication) => <ApplicationStatusChip status={row.status}/>,
        },
        {
            id: "comment",
            label: "Izoh",
            align: "center",
            minWidth: 50,
            style: {
                maxWidth: "300px",
                width: "300px"
            },
            format: (row: IAgentApplication) => (
                <ExpandableText text={row.comment}/>
            )
        },
        {
            id: "actions",
            label: "",
            align: "center",
            minWidth: 50,
            style: {
                maxWidth: "100px",
                width: "400px",
            },
            format: (row: IAgentApplication) => {
                return (
                    <Stack direction="row" gap={1}>
                        {row.status !== "rejected" && row.status !== "delivered" ?
                            <Button
                                variant="contained"
                                disabled={loading}
                                color="error"
                                startIcon={<Cancel/>}
                                onClick={() => handleCancel(row.id)}
                                style={{whiteSpace: "nowrap", textTransform: "capitalize"}}
                            >
                                Bekor qilish
                            </Button> : null}
                        {row.status === "pending" ?
                            <Button
                                variant="contained"
                                disabled={loading}
                                color="primary"
                                startIcon={<CheckCircle/>}
                                style={{whiteSpace: "nowrap", textTransform: "capitalize"}}
                                onClick={() => handleConfirm(row.id)}
                            >
                                Tasdiqlash
                            </Button> : null}
                    </Stack>
                );
            },
        },
    ];

    function handleCancel(id: number) {
        setLoading(true)
        agentApiServices.update(id, {status: "rejected"})
            .then(() => {
                setLoading(false)
                fetchApplications()
                setToast({
                    message: "Ariza bekor qilindi",
                    severity: "success",
                    isOpen: true
                })
            }).catch(() => {
            setLoading(false)
        })
    }

    function handleConfirm(id: number) {
        setLoading(true)
        agentApiServices.update(id, {status: "approved"})
            .then(() => {
                setLoading(false)
                fetchApplications()
                setToast({
                    message: "Ariza tasdiqlandi",
                    severity: "success",
                    isOpen: true
                })
            }).catch(() => {
            setLoading(false)
        })
    }


    return (
        <div className="users">
            <div className="table">
                <DataTable
                    isLoading={loading}
                    total={rows.count}
                    rows={rows.results}
                    columns={columns}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            {
                toast ?
                    <Toast
                        {...toast}
                        handleClose={() => setToast(undefined)}
                    /> : null}
        </div>
    );
}

export default AgentApplications;