import {requests} from "./requests";
import type {IApplicationStatus} from "../types/application.types";

export interface IUpdatedAgent {
    status: IApplicationStatus;
    rejected_reason?: string;
    rejected_by?: number | null;
    comment?: string;
    containers_count: number;
}

export const agentApiServices = {
    update: async (
        id: number,
        updated: Partial<IUpdatedAgent>
    ) => {
        const url = `bank/agent-application-update/${id}/`;
        return await requests("PUT", url, updated);
    },
}